import axios from 'axios'

export default {
	login(username,password) {
		const options = {
			xsrfCookieName: 'XSRF-TOKEN',
			xsrfHeaderName: 'X-XSRF-TOKEN',
			username: username, 
			password: password
		};		
		return axios.post('/tract/api/auth',options)
	},	
}	