<template>
	<div>
		<v-card>
			<v-card-title					
				v-if="access>=1"
			>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					title="Search/Filter users"
					single-line
					hide-details
				></v-text-field>
				<v-icon
					medium
					class="mr-2"
					@click="addItem()"
					color="green"
					title="Add a new user"
					style="margin-left:20px;"
				>
					mdi-plus
				</v-icon>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="detectorList"
				:items-per-page="15"				
				:search="search"
			>
				<template v-slot:item="{ item}">
					<tr :data-id="item.id" style="text-align:left;">
						<td v-if="!item.deleted">{{ item._id }}</td>
						<td v-if="item.deleted" style="color:red;">{{ item._id }}</td>
						<td v-if="!item.deleted">{{ item.deviceType }}</td>
						<td v-if="item.deleted" style="color:red;">{{ item.deviceType }}</td>
						<td v-if="!item.deleted">{{ item.ownerName }}</td>
						<td v-if="item.deleted" style="color:red;">{{ item.ownerName }}</td>
						<td v-if="!item.deleted">{{ item.name }}</td>
						<td v-if="item.deleted" style="color:red;">{{ item.name }}</td>
						<td v-if="!item.deleted">{{ item.ip }}</td>
						<td v-if="item.deleted" style="color:red;">{{ item.ip }}</td>
						<td>
							<v-icon
								medium
								class="mr-2"
								@click="editItem(item)"
								color="green"
								:title="'Edit detector '+item._id"
							>
								mdi-border-color
							</v-icon>
							<v-icon
								medium
								v-if="item._id && item._id!=='' && !item.deleted"
								@click="deleteItem(item)"
								:title="'Delete detector '+item._id"
							>
								mdi-delete
							</v-icon>
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-card>	
		<v-row>
			<v-col v-if="access>=2" style="margin-top:-10px;">
				<v-checkbox
					v-model="showDeleted"
					label="Show Deleted"
					@change="getDetectors"
				></v-checkbox>
			</v-col>
		</v-row>
		<v-dialog
			v-model="addDialog"
			transition="dialog-top-transition"
			max-width="600px"
		>
			<template>
				<v-card>
					<v-toolbar
						color="green"
					>Add detector</v-toolbar>
					<v-row style="margin-left:10px; margin-right:10px;">
						<v-col cols=12>
							<v-text-field
								v-model="detectorName"
								label="Name"
								style="margin-top:10px;"
							></v-text-field>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="selectedDeviceType"
								label="Device Type"
								:items="deviceType"
								item-text="label"
								item-value="id"
							></v-select>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="selectedEntity"
								label="Owner"
								:items="filteredData"
								item-text="name"
								item-value="_id"
							></v-select>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-text-field
								v-model="password"
								v-on:keyup.enter="onEnter"
								:rules="[() => !!password || 'This field is required']"
								:append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
								:type="showPassword ? 'text' : 'password'"
								label="Password"
								placeholder="Password"
								counter
								required
								@keydown.enter="login"
								@click:append="showPassword = !showPassword"
							/>
						</v-col>
						<v-col cols=12 style="margin-top:-25px;margin-bottom:10px;">
							<v-text-field
								v-model="confirmPassword"
								v-on:keyup.enter="onEnter"
								:rules="[() => !!confirmPassword || 'This field is required']"
								:append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
								:type="showConfirmPassword ? 'text' : 'password'"
								label="Confirm Password"
								placeholder="confirmPassword"
								counter
								required
								@keydown.enter="login"
								@click:append="showConfirmPassword = !showConfirmPassword"
							/>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="detectorAccessLevel"
								label="Access Level"
								:items="filteredAccessLevel"
								item-text="level"
								item-value="index"
							></v-select>
						</v-col>
					</v-row>					
					<v-card-actions>
						<v-row>
							<v-col cols=12 style="text-align:right">
								<v-btn
									text
									@click="saveDetector"
								>Save</v-btn>
								<v-btn
									text
									@click="addDialog = false"
								>Close</v-btn>
							</v-col>
							<v-col v-if="saveErrorMessage!==''" cols=12  style="text-align:left; background-color:red;">
								{{saveErrorMessage}}
							</v-col>
						</v-row>					
					</v-card-actions>
				</v-card>
			</template>
		</v-dialog>			
		<v-dialog
			v-model="editDialog"
			transition="dialog-top-transition"
			max-width="600px"
		>
			<template>
				<v-card>
					<v-toolbar
						color="green"
					>Edit detector</v-toolbar>
					<v-row style="margin-left:10px; margin-right:10px;">
						<v-col cols=12>
							<v-text-field
								v-model="detectorName"
								label="Name"
								item-text="name"
								item-value="name"
								style="margin-top:10px;"
							></v-text-field>
						</v-col>
						<v-col cols=6 v-if="access>=2" style="margin-top:-45px;">
							<v-checkbox
								v-model="itemDeleted"
								label="Deleted"
								v-if="wasDeleted"
							></v-checkbox>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="currentDetector"
								label="Owner"
								:items="filteredData"
								item-text="name"
								item-value="_id"
								@change="selectedOwner"
							></v-select>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-text-field
								v-model="password"
								v-on:keyup.enter="onEnter"
								:rules="[() => !!password || 'This field is required']"
								:append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
								:type="showPassword ? 'text' : 'password'"
								label="Password"
								placeholder="Password"
								counter
								required
								@keydown.enter="login"
								@click:append="showPassword = !showPassword"
							/>
						</v-col>
						<v-col cols=12 style="margin-top:-25px;margin-bottom:10px;">
							<v-text-field
								v-model="confirmPassword"
								v-on:keyup.enter="onEnter"
								:rules="[() => !!confirmPassword || 'This field is required']"
								:append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
								:type="showConfirmPassword ? 'text' : 'password'"
								label="Confirm Password"
								placeholder="confirmPassword"
								counter
								required
								@keydown.enter="login"
								@click:append="showConfirmPassword = !showConfirmPassword"
							/>
						</v-col>
						<v-col cols=12 style="margin-top:-35px;">
							<v-select
								v-model="detectorAccessLevel"
								label="Access Level"
								:items="filteredAccessLevel"
								item-text="level"
								item-value="index"
							></v-select>
						</v-col>
					</v-row>					
					<v-card-actions class="justify-end">
						<v-btn
							text
							@click="updateDetector"
						>Save</v-btn>
						<v-btn
							text
							@click="editDialog = false"
						>Close</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</v-dialog>			
	</div>
</template>
<script>
	import detector from '@/services/detector.js'
	export default {
		name: 'detector',

		components: {
		},
		data: () => ({
			saveErrorMessage:'',
			password:'',
			showPassword:false,
			confirmPassword:'',
			showConfirmPassword:false,
			detectorAccessLevel:{},
			search: '',
			accessLevels: [
				{ index:0, level:"User" },
				{ index:1, level:"Administrator" },
				{ index:2, level:"System Administrator" },
			],
			deviceType: [
				{ id: 'Barcode', label: 'Barcode Reader'},
				{ id: 'BLE', label: 'BLE'},
				{ id: 'GNSS', label: 'GNSS'},
				{ id: 'GPS', label: 'GPS'},
				{ id: 'QR', label: 'QR Code Reader'},
				{ id: 'RFID', label: 'RFID Reader'},
			],
			selectedDeviceType:{},
			accessLevel:{},
			headers: [
				{ text: 'MAC', value: '_id' },
				{ text: 'Device Type', value: 'deviceType' },
				{ text: 'Owner', value: 'customer' },
				{ text: 'Name', value: 'name' },
				{ text: 'IP Address', value: 'ip' },
				{ text: 'Actions', value: 'actions', sortable: false },
			],
			rowsAmount: [15, 20, 25, 50, -1],
			detectorList: [],
			footer:{
				'items-per-page-options':[15, 20, 25, 50, -1]
			},		  
			addDialog: false,
			newSaveError:"",
			editDialog: false,
			selectedDetector: {},
			selectedEntity:{},
			entityList:[],
			currentDetector: {},
			selfOwner: false,
			ownerid:'',
			detectorName: "",
			superUser: true,
			deleteError:'',
			
			showDeleted:false,
			
			itemDeleted:false,
			wasDeleted:false,
			
			id:"",
		}),
		methods: {
			getAccessLevel(level){
				var result="";
				if (!level) level=0;
				this.accessLevels.forEach(function(item){
					if (item.index===level)
						result=item.level;
				})
				
				return result;
			},
			addItem: function() {
				this.selectedDetector={};
				this.addDialog=true;
				this.ownerid='';
				this.detectorName="";
				this.password="";
				this.selectedEntity="";
				this.showPassword=false;
				this.confirmPassword="";
				this.showConfirmPassword=false;
				this.detectorAccessLevel=0;
				this.saveErrorMessage="";
			},
			editItem: function(item) {
				this.currentDetector=item;
				this.editDialog=true;
				this.ownerid=item.owner;
				if (item.deleted)
				{
					this.wasDeleted=true;
					this.itemDeleted=true;
				}
				else
				{
					this.wasDeleted=false;
					this.itemDeleted=false;
				}
				this.detectorName=item.detectorName;
				this.id=item.id;
				this.password=item.password;
				this.showPassword=false;
				this.confirmPassword=item.password;
				this.showConfirmPassword=false;
				this.detectorAccessLevel={};
				var me=this;
				this.saveErrorMessage="";
				this.accessLevels.forEach(function(accessItem)
				{
					if (accessItem.index===item.access||accessItem.index===0&&!item.access)
						me.detectorAccessLevel=accessItem;
				});
			},
			selectedOwner: function(item) {
				this.ownerid=item._id;
			},
			saveDetector: function()
			{
				var valid=true;
				var detectorData={};
				if (this.password===""||this.password!==this.confirmPassword)
				{
					valid=false;
					if (this.password==="")
						this.saveErrorMessage="Password not set, please make sure all fields are completed before saving again.";
					else
						this.saveErrorMessage="Password does not match confirmation password.  Please update passwords before saving again.";
				}
				else
					detectorData.password=this.password;
					
				if (valid)
				{
					if (this.detectorAccessLevel>=0)
						detectorData.access=this.detectorAccessLevel;
					else
					{
						valid=false;
						this.saveErrorMessage="User access level not set, please make sure all fields are completed before saving again.";
					}
				}
				if (valid)
				{
					if (!this.selectedEntity)
					{
						valid=false;
						this.saveErrorMessage="Owner required, please make sure all fields are completed before saving again.";
					}
					else
						detectorData.entity=this.selectedEntity;
				}
				if (valid)
				{
					if (!this.detectorName)
					{
						valid=false;
						this.saveErrorMessage="User name required, please make sure all fields are completed before saving again.";
					}
					else
						detectorData.detectorName=this.detectorName;
				}
					
				if (valid)
				{
					console.log("add detector");
					console.log(detectorData);
					detector.addDetector(detectorData, this.$store.getters.token).then(response => {
						if (response && response.data && response.data.access_token)
						{
							this.$store.commit('setToken', response.data.access_token);
							this.addDialog=false;
							this.getDetectors();
						}
						else
						{
							this.$store.commit('LogOut');
						}
					})
					.catch(error => {
						console.log(error);
						this.newSaveError=error;
					});
				}
			},			
			updateDetector: function()
			{
				var detectorData={};
				detectorData.id=this.id;
				detectorData.name=this.detectorName;
				if (this.itemDeleted)
					detectorData.deleted=true;
				detector.updateDetector(detectorData, this.$store.getters.token).then(response => {
					if (response && response.data && response.data.access_token)
					{
						this.$store.commit('setToken', response.data.access_token);
						this.editDialog=false;
						this.getDetectors();
					}
					else
					{
						this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
					this.newSaveError=error;
				});
			
			},			
			deleteItem: function(item) {
				detector.deleteDetector(item.id, this.$store.getters.token).then(response => {
					if (response && response.data && response.data.access_token)
					{
						this.$store.commit('setToken', response.data.access_token);
						//this.addDialog=false;
						this.getDetectors();
					}
					else
					{
						this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
					this.deleteError=error;
				});
			},
			changed: function(currentSelection){
				this.selectedTab=currentSelection
			},
			getDetectors: function() {
				detector.detectors(this.$store.getters.token, this.showDeleted).then(response => {
					console.log(response);
					if (response && response.data && response.data.Detectors)
					{
						this.detectorList=response.data.Detectors;
						console.log(response.data);
						if (response.data.entities&&response.data.entities.length>0)
							this.entityList=response.data.entities;
						else
							this.entityList=[{_id:response.data.users[0].entity,name:response.data.users[0].ownerName}];
					}
					else
					{
						this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
				});
			}
		},
		computed: {
			access () {
				var result=0;
				
				if (this.$store.state.access)
					result=this.$store.state.access;
				return result;
			},
			filteredData(){
				var result=this.entityList.filter(item => (item.deleted !== true || this.showDeleted));
				return result;
			},
			filteredAccessLevel(){
				var result=this.accessLevels.filter(item => (item.index <= this.$store.state.access));
				return result;
			},
		},
		created() {
			this.getDetectors();
		}
	}
</script>
