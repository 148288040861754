import axios from 'axios'
axios.timeout=31000;
export default {
	users(token, showDeleted) {
		const headers = {};		
		var options={};
		
		if (token)
			headers.authorization='Bearer ' + token;
		if (showDeleted)
			options.showdeleted=showDeleted;
		return axios.post('/tract/api/users', options, {headers:headers});
	},	
	addUser(user, token) {
		const options = {
		};		
		if (token)
			options.authorization='Bearer ' + token;
		return axios.post('/tract/api/users/new', user, {headers:options});
	},		
	updateUser(user, token) {
		const options = {
		};		
		if (token)
			options.authorization='Bearer ' + token;
		return axios.post('/tract/api/users/update', user, {headers:options});
	},		
	deleteUser(id, token) {
		const options = {
		};		
		if (token)
			options.authorization='Bearer ' + token;
		return axios.post('/tract/api/users/delete', {id:id}, {headers:options});
	},		
}	