import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import axios from 'axios'

Vue.config.productionTip = false
Vue.prototype.$http = axios

axios.defaults.baseURL = 'https://test.iotx3.com'
axios.defaults.headers.get['Accept'] = 'application/json'

new Vue({
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')


