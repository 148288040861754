<template>
	<div style="margin-top:15px; margin-left:15px;">
		<v-app-bar v-if="mini">
				<v-row>
					<v-col title="Settings menu" cols=3 style="text-align:left;" @click="mini = false">
						<v-icon v-if="mini" title="Settings menu" @click="mini = false">mdi-menu</v-icon> 
						Menu
					</v-col>
					<v-col cols=9 style="text-align:left;">
						<v-icon style="margin-right:10px">{{selectedTabIcon}}</v-icon> {{selectedTabLabel}}
					</v-col>
				</v-row>
		</v-app-bar>
		<v-row v-if="!mini">
			<v-col>
				<v-navigation-drawer
				v-model="drawer"
				:mini-variant.sync="mini"
				permanent
				>
					<v-list-item class="px-2">
						<v-btn
						icon
						@click.stop="mini = !mini"
						>
							<v-icon>mdi-chevron-left</v-icon>
						</v-btn>
					</v-list-item>

					<v-divider></v-divider>

					<v-treeview v-if="!mini" :items="filteredItems" activateable open-all>
						<template v-slot:label="item">
							<div v-if="!item.item.children" @click="changed(item.item)">
								<v-icon>{{ item.item.icon }}</v-icon> {{item.item.title}}
							</div>
							<div v-else>
								{{item.item.title}}
							</div>
						</template>
					</v-treeview>
				</v-navigation-drawer>		
			</v-col>
		</v-row>
		<div v-if="selectedTab==='entity'" style="margin-top:25px;">
			<entity/>
		</div>
		<div v-if="selectedTab==='user'" style="margin-top:25px;">
			<user/>
		</div>
		<div v-if="selectedTab==='detector'" style="margin-top:25px;">
			<detector/>
		</div>
	</div>
</template>
<script>
	import entity from '@/components/Setup/entity';
	import user from '@/components/Setup/user';
	import detector from '@/components/Setup/detector';
	export default {
		name: 'Setup',

		components: {
			entity,
			user,
			detector
		},
		data: () => ({
			selectedMainTab:'access',
			selectedTab:'user',
			selectedTabLabel:'User',
			selectedTabIcon:'mdi-account-multiple',
			items: [
				{id:1, title: 'Access', name: 'Access', children:[
						{ id:2, title: 'User', icon: 'mdi-account-multiple', name:'user', access:0 },
						{ id:3, title: 'Entity', icon: 'mdi-office-building', name:'entity', access:1 },
					]},
				{id:4, title: 'System', name: 'System', children:[
						{ id:5, title: 'Detector', icon: 'mdi-signal-variant', name:'detector', access:2 },
						{ id:6, title: 'Beacon', icon: 'mdi-antenna', name:'beacon', access:2 },
					]},
			],			
			drawer: false,
			mini: true,
		}),
		methods: {
			changed: function(item){
				this.selectedTabIcon=item.icon;
				this.selectedTabLabel=item.title;
				this.selectedTab=item.name;
				this.mini=true;
			},
			changedMain: function(currentSelection){
				this.selectedMainTab=currentSelection;
				if (currentSelection==="access")
					this.selectedTab="users";
				if (currentSelection==="system")
					this.selectedTab="detector";
			},
		},
		computed: {
			access () {
				var result=0;
				
				if (this.$store.state.access)
					result=this.$store.state.access;
					
					console.log(this.$store.state.access);
				return result;
			},
			filteredItems() {
				var result=[];
				var access=0;

				if (this.$store.state.access)
					access=this.$store.state.access;
				
				
				this.items.forEach(function(item){
					var filterChildren=[];
					var minAccess=2;
					item.children.forEach(function(child){
						if (access>=child.access)
						{
							filterChildren.push(child);
							if (child.access<minAccess)
								minAccess=child.access;
						}

					});
					item.children=filterChildren;
					if (access>=minAccess)
						result.push(item);
				});
				
				return result;
			}
		},
		created() {
		}
	}
</script>
