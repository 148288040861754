<template>
	<div id="app" style="height: 100%; width: 100%; margin-top:5px;">
		<div style="height: 90%; width: 100%">
			<div>
				<p>First marker is placed at {{ withPopup.lat }}, {{ withPopup.lng }}</p>
				<p>Center is at {{ currentCenter }} and the zoom is: {{ currentZoom }}</p>
				<button @click="showLongText">
				Toggle long popup
				</button>
				<button @click="showMap = !showMap">
				Toggle map
				</button>
			</div>
			<l-map
					v-if="showMap"
					:zoom="zoom"
					:center="center"
					:options="mapOptions"
					style="height: 80%"
					@update:center="centerUpdate"
					@update:zoom="zoomUpdate"
			>
				<l-tile-layer
					:url="url"
					:attribution="attribution"
				/>
				<l-marker :lat-lng="withPopup">
					<l-popup>
						<div @click="innerClick">
							I am a popup
							<p v-show="showParagraph">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
							sed pretium nisl, ut sagittis sapien. Sed vel sollicitudin nisi.
							Donec finibus semper metus id malesuada.
							</p>
						</div>
					</l-popup>
				</l-marker>
				<l-marker :lat-lng="withTooltip" @click="innerClick">
					<l-tooltip :options="{ permanent: true, interactive: true }">
						<div @click="innerClick">
							I am a tooltip
							<p v-show="showParagraph">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
							sed pretium nisl, ut sagittis sapien. Sed vel sollicitudin nisi.
							Donec finibus semper metus id malesuada.
							</p>
						</div>
					</l-tooltip>
				</l-marker>
				<l-marker :lat-lng="withTooltip2" :icon="defaultIcon">
					<l-tooltip :options="{ permanent: true, interactive: true }">
						<div @click="innerClick">
							I am a tooltip
							<p v-show="showParagraph">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
							sed pretium nisl, ut sagittis sapien. Sed vel sollicitudin nisi.
							Donec finibus semper metus id malesuada.
							</p>
						</div>
					</l-tooltip>
				</l-marker>
				<l-marker :lat-lng="withTooltip3" :icon="getIcon(51,'green')">
				</l-marker>
			</l-map>
		</div>  
	</div>
</template>
<script>
	import L from 'leaflet';
	import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";

	export default {
		name: 'Location',

		components: {
			LMap,
			LTileLayer,
			LMarker,
			LPopup,
			LTooltip	
		},
		data: () => ({
			zoom: 7,
			center: [-31.5, 150.5],
			defaultIcon: L.icon({
				iconUrl: 'http://leafletjs.com/examples/custom-icons/leaf-green.png',
				shadowUrl: 'http://leafletjs.com/examples/custom-icons/leaf-shadow.png',
				iconSize:     [19, 47],
				shadowSize:   [25, 32],
				iconAnchor:   [11, 46],
				shadowAnchor: [4, 31],
				popupAnchor:  [-3, -76]
			}),
			url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
			attribution:
				'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
			withPopup: [-33.768960, 150.934710],
			withTooltip: [-33.746608, 150.695488],
			withTooltip2: [-30.515598, 151.647781],
			withTooltip3: [-33.772442, 151.165176],
			currentZoom: 7,
			currentCenter: [-33.768960, 150.934710],
			showParagraph: false,
			mapOptions: {
				zoomSnap: 0.5
			},
			showMap: true
		}),
		methods: {
			zoomUpdate(zoom) {
				this.currentZoom = zoom;
			},
			centerUpdate(center) {
				this.currentCenter = center;
			},
			showLongText() {
				this.showParagraph = !this.showParagraph;
			},
			innerClick() {
				alert("Click!");
			},
			getIcon(number,colour)
			{
				if (!colour)
					colour="orange";
				var ldivicon=L.divIcon({
					className: "number-icon "+colour,
					iconSize: [25, 25],
					iconAnchor: [12, 20],
					popupAnchor: [3, -40],
					html: number   
				});
				
				return ldivicon;
			}
		},			
		created() {
		}	
	}
</script>

<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		margin-top: 60px;
	}
	.number-icon
	{
		text-align:center;
		color:White; 
	}
</style>