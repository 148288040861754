import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		user:'',
		token:'',
		access:0,
		darkMode:false,
		mainTab:0,
  },
  mutations: {
    setDarkmode(state, mode){
        state.darkMode = mode
    },
    setUser(state, details){
        state.user = details.username
        state.token = details.token
		state.access = details.access
		state.mainTab=0
    },
    setToken(state, token){
        state.token = token
    },
    setMainTab(state, newtab){
        state.mainTab = newtab
    },
    LogOut(state){
        state.user = null
		state.token = "";
    },	  
  },
  actions: {
  },
  getters: {
	authenticated: state => {
		return !!state.user
	},
	user: state => {
		return state.user
	},
	access: state => {
		return state.access
	},
	token: state => {
		return state.token
	},
	darkMode: state => {
		return state.darkMode;
	},
	mainTab: state => {
		return state.mainTab;
	}
  },
	plugins: [createPersistedState()]
})
