<template>
  <v-main>
    <v-container
		fill-height
		fluid
		v-if="darkMode"
	>
      <v-layout
        align-center
        justify-center>
        <v-flex
          xs12
          sm8
          md4>
          <v-card
            class="elevation-12">
            <v-toolbar
              color="grey darken-3" height="55">
				<v-img
						:src="logo"
						contain
						height="45"
					/>

              <v-spacer/>
            </v-toolbar>
            <v-card-text>
              <v-form >
                <v-text-field
                  ref="username"
                  v-model="username"
                  :rules="[() => !!username || 'This field is required']"
                  prepend-icon="mdi-account"
                  label="Login"
                  placeholder="User name"
                  required
					v-on:keyup.enter="onEnter"
                />
                <v-text-field
                  ref="password"
                  v-model="password"
					v-on:keyup.enter="onEnter"
                  :rules="[() => !!password || 'This field is required']"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  label="Password"
                  placeholder="Password"
                  counter
                  required
                  @keydown.enter="login"
                  @click:append="showPassword = !showPassword"
                />
				<div v-if="errorMessage!==''" style="background-color:red;color:white;">
					{{errorMessage}}
				</div>
              </v-form>
            </v-card-text>
            <v-divider class="mt-5"/>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                align-center
                justify-center
                color="general"
				v-if="allowLogin"
                @click="login">Login
              </v-btn>
            </v-card-actions>
            <v-snackbar
              v-model="snackbar"
              :color="color"
              :top="true"
            >
              <v-btn
                dark
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </v-snackbar>
          </v-card>
        </v-flex>
      </v-layout>

    </v-container>
  </v-main>
</template>

<script>
import auth from '@/services/authentication.js'
export default {
  data: function () {
    return {
		logo: 'tract full.png',
		username: '',
		password: '',
		errorMessage: '',
		snackbar: false,
		color: 'general',
		showPassword: false,
		allowLogin: true,
    }
  },

  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
		login: function () {
			if (this.allowLogin)
			{
				this.errorMessage='';
				this.allowLogin=false;
				auth.login(this.username,this.password)
					.then(response => {
						if (response && response.data && response.data.access_token && response.data.username)
						{
							this.$store.commit('setUser', {username:response.data.username, access:response.data.access, token:response.data.access_token });
						}
						else
						{
							console.log(response);
							this.errorMessage="Login failed.  Check username and password."
							var me=this;
							var timeout=2;
							if (response.data.failures*2.0>2)
								timeout=response.data.failures*2.0-2;
							setTimeout(function(){me.allowLogin=true;},timeout*1000.0);						
						}
					})
					.catch(error => {
						console.log(error);
					});
			}
		},
		onEnter: function() {
			this.login();
		}
	},
	computed: {
		darkMode : function() {
			return true;
		},
	},
	metaInfo () {
		return {
			title: 'Tract Login'
		}
	}
}
</script>
