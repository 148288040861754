<template>
	<div>
		<v-card>
			<v-card-title>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					title="Search/Filter entities"
					single-line
					hide-details
				></v-text-field>
				<v-icon
					medium
					class="mr-2"
					@click="addItem()"
					color="green"
					title="Add a new entity"
					style="margin-left:20px;"
				>
					mdi-plus
				</v-icon>
			</v-card-title>
			<v-data-table
				:headers="headers"
				:items="EntityList"
				:items-per-page="15"				
				:search="search"
			>
				<template v-slot:item="{ item}">
					<tr :data-id="item.id" style="text-align:left;">
						<td v-if="!item.deleted">{{ item.name }}</td>
						<td v-if="item.deleted" style="color:red;">{{ item.name }}</td>
						<td v-if="!item.deleted">{{ item.ownerName }}</td>
						<td v-if="item.deleted" style="color:red;">{{ item.ownerName }}</td>
						<td>
							<v-icon
								medium
								class="mr-2"
								@click="editItem(item)"
								color="green"
								:title="'Edit entity '+item.name"
							>
								mdi-border-color
							</v-icon>
							<v-icon
								medium
								v-if="item.id && item.id!=='' && !item.deleted"
								@click="deleteItem(item)"
								:title="'Delete entity '+item.name"
							>
								mdi-delete
							</v-icon>
						</td>
					</tr>
				</template>
			</v-data-table>
		</v-card>	
		<v-row>
			<v-col v-if="access>=2" style="margin-top:-10px;">
				<v-checkbox
					v-model="showDeleted"
					label="Show Deleted"
					@change="getEntities"
				></v-checkbox>
			</v-col>
		</v-row>
		<v-dialog
			v-model="addDialog"
			transition="dialog-top-transition"
			max-width="600px"
		>
			<template>
				<v-card>
					<v-toolbar
						color="green"
					>Add entity</v-toolbar>
					<v-row style="margin-left:10px; margin-right:10px;">
						<v-col cols=12>
							<v-text-field
								v-model="entityName"
								label="Name"
								style="margin-top:10px;"
							></v-text-field>
						</v-col>
						<v-col v-if="access>=2" style="margin-top:-35px;">
							<v-checkbox
								v-model="selfOwner"
								label="Self owned"
								value="true"
							></v-checkbox>
						</v-col>
						<v-col cols=12 v-if="!selfOwner" style="margin-top:-35px;">
							<v-select
								v-model="selectedEntity"
								label="Owner"
								:items="uniqueEntityList"
								item-text="name"
								item-value="_id"
							></v-select>
						</v-col>
					</v-row>					
					<v-card-actions>
						<v-row>
							<v-col cols=12 style="text-align:right">
								<v-btn
									text
									@click="saveEntity()"
								>Save</v-btn>
								<v-btn
									text
									@click="addDialog = false"
								>Close</v-btn>
							</v-col>
							<v-col v-if="newSaveError!==''" cols=12  style="text-align:left; background-color:red;">
								{{newSaveError}}
							</v-col>
						</v-row>					
					</v-card-actions>
				</v-card>
			</template>
		</v-dialog>			
		<v-dialog
			v-model="editDialog"
			transition="dialog-top-transition"
			max-width="600px"
		>
			<template>
				<v-card>
					<v-toolbar
						color="green"
					>Edit entity</v-toolbar>
					<v-row style="margin-left:10px; margin-right:10px;">
						<v-col cols=12>
							<v-text-field
								v-model="entityName"
								label="Name"
								item-text="name"
								item-value="name"
								style="margin-top:10px;"
							></v-text-field>
						</v-col>
						<v-col cols=6 v-if="access>=2" style="margin-top:-35px;">
							<v-checkbox
								v-model="selfOwner"
								label="Self owned"
							></v-checkbox>
						</v-col>
						<v-col cols=6 v-if="access>=2" style="margin-top:-35px;">
							<v-checkbox
								v-model="itemDeleted"
								label="Deleted"
								v-if="wasDeleted"
							></v-checkbox>
						</v-col>
						<v-col cols=12 v-if="!selfOwner" style="margin-top:-35px;">
							<v-select
								v-model="ownerid"
								label="Owner"
								:items="uniqueEntityList"
								item-text="name"
								item-value="_id"
							></v-select>
						</v-col>
					</v-row>					
					<v-card-actions class="justify-end">
						<v-btn
							text
							@click="uptdateEntity"
						>Save</v-btn>
						<v-btn
							text
							@click="editDialog = false"
						>Close</v-btn>
					</v-card-actions>
				</v-card>
			</template>
		</v-dialog>			
	</div>
</template>
<script>
	import entity from '@/services/entity.js'
	export default {
		name: 'Entity',

		components: {
		},
		data: () => ({
			search: '',
			headers: [
				{ text: 'Name', value: 'name' },
				{ text: 'Owner', value: 'ownerName' },
				{ text: 'Actions', value: 'actions', sortable: false },
			],
			rowsAmount: [15, 20, 25, 50, -1],
			EntityList: [],
			footer:{
				'items-per-page-options':[15, 20, 25, 50, -1]
			},		  
			addDialog: false,
			newSaveError:"",
			editDialog: false,
			selectedEntity: {},
			currentEntity: {},
			selfOwner: false,
			ownerid:'',
			entityName: "",
			superUser: true,
			deleteError:'',
			
			showDeleted:false,
			
			itemDeleted:false,
			wasDeleted:false,
			
			id:"",
		}),
		methods: {
			addItem: function() {
				this.selectedEntity={};
				this.addDialog=true;
				this.selfOwner=false;
				this.ownerid='';
				this.entityName="";
			},
			editItem: function(item) {
				this.currentEntity=item;
				this.editDialog=true;
				this.ownerid=item.owner;
				console.log(this.ownerid);
				if (item.name===item.ownerName)
				{
					this.selfOwner=true;
				}
				else
				{
					this.selfOwner=false;
				}
				if (item.deleted)
				{
					this.wasDeleted=true;
					this.itemDeleted=true;
				}
				else
				{
					this.wasDeleted=false;
					this.itemDeleted=false;
				}
				this.entityName=item.name;
				this.id=item.id;
			},
			selectedOwner: function(item) {
				this.ownerid=item;
			},
			saveEntity: function()
			{
				var entityData={};
				entityData.name=this.entityName;
				if (this.selfOwner)
					entityData.selfOwner=true;
				else
					entityData.owner=this.selectedEntity;
				entity.addEntity(entityData, this.$store.getters.token).then(response => {
					if (response && response.data && response.data.access_token)
					{
						this.$store.commit('setToken', response.data.access_token);
						this.addDialog=false;
						this.getEntities();
					}
					else
					{
						this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
					this.newSaveError=error;
				});
			
			},			
			uptdateEntity: function()
			{
				var entityData={};
				entityData.id=this.id;
				entityData.name=this.entityName;
				if (this.selfOwner)
					entityData.selfOwner=true;
				else
				{
					entityData.owner=this.ownerid;
					console.log(this.ownerid);
				}
				if (this.itemDeleted)
					entityData.deleted=true;
				entity.updateEntity(entityData, this.$store.getters.token).then(response => {
					if (response && response.data && response.data.access_token)
					{
						this.$store.commit('setToken', response.data.access_token);
						this.editDialog=false;
						this.getEntities();
					}
					else
					{
						this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
					this.newSaveError=error;
				});
			
			},			
			deleteItem: function(item) {
				entity.deleteEntity(item.id, this.$store.getters.token).then(response => {
					if (response && response.data && response.data.access_token)
					{
						this.$store.commit('setToken', response.data.access_token);
						//this.addDialog=false;
						this.getEntities();
					}
					else
					{
						this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
					this.deleteError=error;
				});
			},
			changed: function(currentSelection){
				this.selectedTab=currentSelection
			},
			getEntities: function() {
				entity.entities(this.$store.getters.token, this.showDeleted).then(response => {
					if (response && response.data && response.data.Entities)
					{
						this.EntityList=response.data.Entities;
					}
					else
					{
						this.$store.commit('LogOut');
					}
				})
				.catch(error => {
					console.log(error);
				});
			}
		},
		computed: {
			uniqueEntityList() {
				var result=[];
				var me=this;
				console.log(me.currentEntity);
				this.EntityList.forEach(function(item){
					var found=false;
					
					result.forEach(function(resultItem){
						if (resultItem.id===item.id)
							found=true;
					});
					
					if (!found)
						result.push(item);
				});
				return result;
			},
			access () {
				var result=0;
				
				if (this.$store.state.access)
					result=this.$store.state.access;
				return result;
			},
		},
		created() {
			this.getEntities();
		}
	}
</script>
