import axios from 'axios'

export default {
	entities(token, showDeleted) {
		const headers = {};		
		var options={};
		
		if (token)
			headers.authorization='Bearer ' + token;
		if (showDeleted)
			options.showdeleted=showDeleted;
		return axios.post('/tract/api/entities', options, {headers:headers});
	},	
	addEntity(entity, token) {
		const options = {
		};		
		if (token)
			options.authorization='Bearer ' + token;
		return axios.post('/tract/api/entity/new', entity, {headers:options});
	},		
	updateEntity(entity, token) {
		const options = {
		};		
		if (token)
			options.authorization='Bearer ' + token;
		return axios.post('/tract/api/entity/update', entity, {headers:options});
	},		
	deleteEntity(id, token) {
		const options = {
		};		
		if (token)
			options.authorization='Bearer ' + token;
		return axios.post('/tract/api/entity/delete', {id:id}, {headers:options});
	},		
}	